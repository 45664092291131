export default function docTitle() {
    const hasLanguage = localStorage.getItem("language")
    if (!hasLanguage) {
        $.ajax({
            async: false,
            type: "GET",
            url: 'https://www.yoni-tech.com/api/ip',
            success: function (msg) {
                const language = msg == 'CN' ? "CN" : "EN"
                localStorage.setItem("language", language)
                document.title =
                    language == "CN"
                        ? "优尼科技-全球短信/语音/邮件/WhatsApp，更快速，更专业, 直连运营商，覆盖全球国家"
                        : "YoniTech-Global SMS/Voice/Email/WhatsApp, faster and more professional, directly connected to operators, covering countries around the world";
            }
        })
    } else {
        document.title =
            hasLanguage == "CN"
                ? "优尼科技-全球短信/语音/邮件/WhatsApp，更快速，更专业, 直连运营商，覆盖全球国家"
                : "YoniTech-Global SMS/Voice/Email/WhatsApp, faster and more professional, directly connected to operators, covering countries around the world";
    }
}