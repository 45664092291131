<template>
  <div>
    <div class="preloader">
      <div class="preloader-wave"></div>
    </div>
    <div class="navbar-area">
      <div class="mobile-nav">
        <a class="logo" @click="goHome">
          <img src="static/picture/logo.png" alt="Logo">
        </a>

      </div>

      <div :class="[{'top-nav':isActive},'main-nav']">
        <div class="container">
          <nav class="navbar navbar-expand-md navbar-light ">
            <a class="navbar-brand" @click="goHome">
              <img src="static/picture/logo.png" alt="Logo">
            </a>
            <a class="navbar-brand-sticky" @click="goHome">
              <img src="static/picture/logo.png" alt="Logo">
            </a>
            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
              <ul class="navbar-nav m-auto">
                <li class="nav-item">
                  <router-link data-lang="menu.home" to="/" class="nav-link">Home</router-link>
                </li>
                <li class="nav-item">
                  <a class="nav-link">
                    <span data-lang="menu.product"></span>
                    <i class='bx bxs-chevron-right'></i>
                  </a>

                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <a class="nav-link">
                        <span data-lang="menu.sms"></span>
                        <i class="bx bx-chevron-right"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li class="nav-item alignCenter">
                          <router-link to="/sms" class="nav-link">
                            <img src="static/image/iconYZM.png" alt="">
                            <span data-lang="foot.product.otp"></span>
                          </router-link>
                        </li>
                        <li class="nav-item alignCenter">
                          <router-link to="/market" class="nav-link">
                            <img src="static/image/iconYxYzm.png" alt="">
                            <span data-lang="foot.product.marketing"></span>
                          </router-link>
                        </li>
                      </ul>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link">
                        <span data-lang="menu.voice"></span>
                        <i class="bx bx-chevron-right"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li class="nav-item alignCenter">
                          <router-link to="/voice" class="nav-link">
                            <img src="static/image/iconYX.png" alt="">
                            <span data-lang="product.voice"></span>
                          </router-link>
                        </li>
                        <li class="nav-item alignCenter">
                          <router-link to="/trunk" class="nav-link">
                            <img src="static/image/iconSIP.png" alt="">
                            <span>SIP Trunk</span>
                          </router-link>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <router-link data-lang="menu.emailHome" to="/email" class="nav-link">
                      </router-link>
                    </li>

                    <li class="nav-item">
                      <router-link data-lang="menu.phone" to="/phoneCheck" class="nav-link">
                      </router-link>
                      <!-- <a  class="nav-link">
                        <span data-lang="menu.phone"></span>
                        <i class="bx bx-chevron-right"></i>
                      </a> -->
                      <!-- <ul class="dropdown-menu">
                        <li class="nav-item alignCenter">
                          <router-link to="/phoneCheck" class="nav-link">
                            <img src="static/image/iconJC.png" alt="">
                            <span data-lang="product.number"></span>
                          </router-link>
                        </li>
                      </ul> -->
                    </li>

                    <li class="nav-item">
                      <a class="nav-link">
                        WhatsApp
                        <i class="bx bx-chevron-right"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li class="nav-item alignCenter">
                          <a href="https://docs-yoni-tech.readme.io/reference/whatsapp_message-send" target="_blank" class="nav-link">
                            <img src="static/image/iconWAP.png" alt="">
                            <span>WhatsApp API</span>
                          </a>
                        </li>
                        <li class="nav-item alignCenter">
                          <router-link to="/campaign" class="nav-link">
                            <img src="static/image/email-campaign.png" alt="">
                            <span>Campaign</span>
                          </router-link>
                        </li>
                        <li class="nav-item alignCenter">
                          <router-link to="/bot" class="nav-link">
                            <img src="static/image/bot.png" alt="">
                            <span>Chatbot</span>
                          </router-link>
                        </li>
                        <li class="nav-item alignCenter">
                          <router-link to="/contact" class="nav-link">
                            <img src="static/image/contact.png" alt="">
                            <span>Contact</span>
                          </router-link>

                        </li>
                        <li class="nav-item alignCenter">
                          <router-link to="/inbox" class="nav-link">
                            <img src="static/image/inbox.png" alt="">
                            <span>Inbox</span>
                          </router-link>

                        </li>
                        <li class="nav-item alignCenter">
                          <router-link to="/growthTool" class="nav-link">
                            <img src="static/image/growth.png" alt="">
                            <span>Growth Tool</span>
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <router-link data-lang="menu.price" to="/price" class="nav-link">Home</router-link>
                </li>
                <li class="nav-item">
                  <a class="nav-link">
                    <span data-lang="menu.docment"></span>
                    <i class='bx bxs-chevron-right'></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">

                      <a class="nav-link" href="https://docs-yoni-tech.readme.io/reference/sms_getting-started" target="_blank">
                        <img src="static/image/iconSMS.png" alt="">
                        <span>SMS</span>
                      </a>
                    </li>
                    <li class="nav-item">

                      <a class="nav-link" href="https://docs-yoni-tech.readme.io/reference/voice_sip-trunk" target="_blank">
                        <img src="static/image/voicesIcon.png" alt="">
                        <span>Voice</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://docs-yoni-tech.readme.io/reference/email_getting-started" target="_blank">
                        <img src="static/image/mailbox.png" alt="">
                        <span>Email</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://docs-yoni-tech.readme.io/reference/whatsapp_getting-started" target="_blank">
                        <img src="static/image/whatsappIcon.png" alt="">
                        <span>WhatsApp</span>
                      </a>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link" href="https://docs-yoni-tech.readme.io/reference/check_getting-started" target="_blank">
                        <img src="static/image/jiancheIcon.png" alt="">
                        <span> Number Check</span>
                      </a>
                    </li>

                  </ul>
                </li>

                <li class="nav-item">
                  <router-link data-lang="menu.about" to="/about" class="nav-link"></router-link>
                </li>
                <li class="nav-item">
                  <a class="nav-link">
                    <span data-lang="menu.language"></span>
                    <i class='bx bxs-chevron-right'></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li class="nav-item">
                      <a id="zhBtn" class="nav-link">
                        简体中文
                      </a>
                    </li>
                    <li class="nav-item">
                      <a id="enBtn" class="nav-link">
                        English
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="menu-btn">
                <a @click="loginClick" data-lang="menu.login" class="seo-btn"></a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
      isActive: false,
      loginFlag: false,
      top: 0,
    }
  },
  watch: {
    $route: function (val) {
      if (val.path == '/') {
        this.isActive = false
      } else {
        this.isActive = true
      }

    },
  },
  mounted() {
  },
  methods: {
    loginClick() {
      this.$emit("loginClick", true)
    },
  },
}
</script>

<style scoped>
.menu-btn > a {
  cursor: pointer;
}

.nav-link {
  cursor: pointer;
  width: 100%;
}
</style>
