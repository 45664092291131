<template>
  <div>
    <footer id="footer" class="footer-area bg-footer">
      <div class="footer-top">
        <div class="container">
          <div class="row lgRow">

            <div class="col-lg-2 col-md-6 smallRow">
              <div class="footer-list ml-50">
                <h3 data-lang="foot.product.title"></h3>
                <ul>
                  <li>
                    <i class='bx bxs-chevron-right'></i>
                    <a data-lang="menu.sms" @click="goToOtp"></a>
                  </li>
                  <li>
                    <i class='bx bxs-chevron-right'></i>
                    <a @click="gotoVoice" data-lang="menu.voice"></a>
                  </li>
                  <li>
                    <i class='bx bxs-chevron-right'></i>
                    <a @click="goToNumber" data-lang="menu.phone"> </a>
                  </li>
                  <li>
                    <i class='bx bxs-chevron-right'></i>
                    <a @click="gotoEmail" data-lang="menu.emailHome"></a>
                  </li>
                  <li>
                    <i class='bx bxs-chevron-right'></i>
                    <a href="https://docs-yoni-tech.readme.io/reference/whatsapp_message-send"> WhatsApp</a>
                  </li>
                </ul>

              </div>
            </div>
            <div class="col-lg-2 col-md-6 smallRow">
              <div class="footer-list ml-50">
                <h3 data-lang="foot.developer.title"></h3>
                <ul>
                  <li>
                    <i class='bx bxs-chevron-right'></i>
                    <a href="https://docs-yoni-tech.readme.io/reference/summary" data-lang="foot.developer.developer1"></a>
                  </li>

                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 smallRow">
              <div class="footer-list ml-20">
                <h3 data-lang="foot.company.title"></h3>
                <ul>
                  <li>
                    <i class='bx bxs-chevron-right'></i>
                    <a @click="goToAbout" data-lang="foot.company.title3"></a>
                  </li>

                  <li>
                    <i class='bx bxs-chevron-right'></i>
                    <a @click="gotoTerm" data-lang="foot.developer.developer3"></a>
                  </li>

                  <li>
                    <i class='bx bxs-chevron-right'></i>
                    <a @click="gotoPrivacyPolicy" data-lang="foot.developer.developer4"></a>
                  </li>

                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 smallRow">
              <div class="footer-list ml-20">
                <h3>Contact Us</h3>
                <ul>
                  <li>
                    <i class='bx bxs-chevron-right'></i>
                    <a>service@yoni-tech.com</a>
                  </li>

                </ul>
              </div>
            </div>

            <div class="footerImg col-md-4 ">
              <div class="footer-single-content">
                <a>
                  <img src="static/image/wechat.jpg" alt="Logo">
                </a>
                <p data-lang="menu.wechat"></p>
              </div>
            </div>

            <div class="footerImg col-md-4">
              <div class="footer-single-content">
                <a href="">
                  <img src="static/image/LinkedIn.png" alt="Logo">
                </a>
                <p data-lang="menu.LinkedIn"></p>
              </div>
            </div>

            <div class="footerImg col-md-4">
              <div class="footer-single-content">
                <a href="">
                  <img src="static/image/facebook.png" alt="Logo">
                </a>
                <p data-lang="menu.facebook"></p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </footer>
  </div>
</template>
<script>
export default {
  methods: {
    screenTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },

    goToOtp() {
      this.$router.push({ path: '/sms' })
      this.screenTop()
    },
    gotoVoice() {
      this.$router.push({ path: '/voice' })
      this.screenTop()
    },
    gotoEmail() {
      this.$router.push({ path: '/email' })
      this.screenTop()
    },
    goToMarket() {
      this.$router.push({ path: '/market' })
    },
    goToVoice() {
      this.$router.push({ path: '/voice' })
      this.screenTop()
    },

    goToNumber() {
      this.$router.push({ path: '/phoneCheck' })
      this.screenTop()
    },
    goToWhatsApp() {
      window.open("https://docs-yoni-tech.readme.io/reference/whatsapp_message-send")
    },
    goToAbout() {
      this.$router.push({ path: '/about' })
      this.screenTop()
    },
    gotoTerm() {
      this.$router.push({ path: '/termsService' })
      this.screenTop()
    },
    gotoPrivacyPolicy() {
      this.$router.push({ path: '/privacyPolicy' })
      this.screenTop()
    },
  },
}
</script>
