


var dict = {};
var that1 = null
function translateVue(that) {
    that1 = that
}

function setLanguage(lang) {
    localStorage.setItem("language", lang)
    translate();
}


function translate() {
    loadDict();

    $("[data-lang]").each(function () {
        switch (this.tagName.toLowerCase()) {
            case "input":
                $(this).attr("placeholder", trDict($(this).attr("data-lang")));
                break;
            case "textarea":
                $(this).attr("placeholder", trDict($(this).attr("data-lang")));
                break;
            default:
                $(this).text(trDict($(this).attr('data-lang')));

        }
    });
}

function trDict(src) {
    const data = src + "." + localStorage.getItem("language")
    return eval(`dict.${data}`)
}

function loadDict() {
    $.ajax({
        async: false,
        type: "GET",
        url: '/static/json/language.json',
        success: function (msg) {
            dict = msg
        }
    });
}



export default function changeLang() {
    const lang = localStorage.getItem("language") || "CN";
    setLanguage(lang);

    $("#enBtn").bind("click", function () {
        setLanguage("EN");
        if (that1) {
            that1.lang = "EN"
        }
        document.title = "YoniTech-Global SMS/Voice/Email/WhatsApp, faster and more professional, directly connected to operators, covering countries around the world";
    });

    $("#zhBtn").bind("click", function () {
        setLanguage("CN");
        if (that1) {
            that1.lang = "CN"
        }
        document.title = "优尼科技-全球短信/语音/邮件/WhatsApp，更快速，更专业, 直连运营商，覆盖全球国家"
    });
}


export {

    translateVue
}

