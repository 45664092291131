<template>
  <div>
    <banner ref="banner" @loginClick="loginShow" />
    <router-view @msg="getMsg" ref="routerView" />
    <foot />
    <div v-if="message" class="alert">
      <div :class="isSuccess ? 'success alert-content' : ' alert-content'">
        {{ message }}
      </div>
    </div>

    <div class="chooseLogin" v-show="loginFlag" :style="{top:topScroll + 'px'}">
      <div class="box">
        <img class="closeImg" src="static/image/close.png" alt="" @click="closeLogin">
        <div class="title" data-lang="menu.chooseProduct"></div>
        <div class="projectList">
          <div class="projectList_top" @click="toLogin">
            <img src="static/image/iconSMS.png" alt="">
            SMS
          </div>
          <div class="projectList_top" @click="toLoginWhatsApp">
            <img src="static/image/Whatsapp1.svg" alt="">
            WhatsApp
          </div>
        </div>

      </div>

    </div>

    <div class="cookies" v-if="onShow">
      <div>
        This website uses cookies to ensure you get the best experience on our
        website.
      </div>
      <div class="button">
        <div class="button-left" @click="onClose">Decline</div>
        <div class="button-right" @click="onClose">Allow All</div>
        <div class="close" @click="onClose">×</div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import docTitle from "./assets/js/document.js";
import banner from "./components/banner.vue";
import foot from "./components/footer.vue"
export default {
  name: 'App',
  components: {
    banner,
    foot
  },
  data() {
    return {
      message: '',
      isSuccess: false,
      topScroll: 0,
      loginFlag: false,
      onShow: true,
    }
  },
  mounted() {
    window.onscroll = () => {
      var top = document.documentElement.scrollTop || document.body.scrollTop;
      this.topScroll = top

    };
    docTitle()
    this.onShow = window.localStorage.getItem("cookies") == 'false' ? false : true
  },
  methods: {
    toLoginWhatsApp() {
      window.open(`https://whatsapp.yoni-tech.com/console/#/entry/login`);
    },
    onClose() {
      this.onShow = false
      document.cookie = 'false'
      window.localStorage.setItem("cookies", false)
    },

    toLogin() {
      let lang = window.localStorage.getItem("language") == 'EN' ? 'en' : 'zh'
      window.open(`https://sgzz.yoni-tech.com/login?lang=${lang}`);
    },
    closeLogin() {
      document.querySelector('body').style.overflow = 'auto'
      this.loginFlag = false
    },
    loginShow(bollean) {
      document.querySelector('body').style.overflow = 'hidden'
      this.loginFlag = bollean
    },
    getMsg(e, boolean) {
      this.message = e;
      this.isSuccess = boolean;
      setTimeout(() => {
        this.message = "";
      }, 1500);
    },
  }
}
</script>

<style scoped>
.alert {
  position: fixed;
  top: 12%;
  left: 0;
  width: 100%;
  z-index: 10070;
}
.alert-content {
  border-radius: 16px;
  text-align: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  line-height: 50px;
  height: 50px;
  width: 500px;
  margin: 0 auto;
  font-size: 20px;
  color: #e6a23c;
  background-color: #fdf6ec;
}
.success {
  background-color: #f0f9eb;
  color: #67c23a;
}

.chooseLogin {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 300px;
  background: url("/public/static/image/result.svg") no-repeat;
  background-size: 100% 100%;
}

.closeImg {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.title {
  font-size: 18px;
  margin: 60px 0 0 90px;
  color: black;
}

.projectList {
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectList_top {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 40px 80px;
  justify-content: center;
  align-items: center;
  color: black;
}

.projectList_top > img {
  width: 50px;
  height: 50px;
}

.projectList_top > img:hover {
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 600px) {
  .box {
    width: auto;
  }
}

.cookies {
  border-radius: 10px;
  box-shadow: 0px 0px 24px -5px #000;
  padding: 18px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80% !important;
  position: fixed;
  bottom: 10px;
  margin: 0 auto;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  font-size: 14px;
  z-index: 10;
}

.button {
  display: flex;
  align-items: center;
}
.button-left {
  cursor: pointer;
  background-color: #1a1e22ff;
  padding: 8px 24px;
  color: #fff;
  font-size: 14px;
}
.button-right {
  cursor: pointer;
  background-color: #1a1e22ff;
  padding: 8px 24px;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  white-space: nowrap;
}
.close {
  cursor: pointer;
  transform: scale(1.5);
  margin-left: 20px;
}

@media screen and (max-width: 616px) {
  .cookies {
    flex-direction: column;
    width: 90% !important;
    font-size: 12px;
  }
  .button {
    margin-top: 5px;
  }

  .button-left {
    padding: 8px 40px;
    border-radius: 15px;
    font-size: 12px;
  }
  .button-right {
    padding: 8px 40px;
    border-radius: 15px;
    font-size: 12px;
  }
}
</style>
