import { createApp, VueElement } from 'vue'
import App from './App.vue'
import router from './router'
import custom from "./assets/js/custom.js";
import changeLang from "./assets/js/language.js";
const app = createApp(App);
app.config.globalProperties.custom = custom
app.config.globalProperties.changeLang = changeLang

app.use(router).mount('#app')
