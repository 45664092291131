<template>
  <div>

    <div class="main-banner">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="banner-text">
              <h1 data-lang="menu.titleTop"></h1>
              <p data-lang="menu.jieshao">
              </p>
              <div class="banner-btn">
                <a @click="gotoContact" class="default-btn" data-lang="foot.company.title2"></a>
                <a @click="gotoAbout" class="default-btn active" data-lang="menu.knowMore"></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="banner-img">
              <img src="static/picture/home1.png" alt="Banner Images">
            </div>
          </div>
          <div class="banner-shape-1">
            <img src="static/picture/home11.png" alt="Banner Shape">
          </div>
        </div>
      </div>
      <div class="home-shape">
        <div class="shape1">
          <img src="static/picture/1.png" alt="shape">
        </div>
        <div class="shape2">
          <img src="static/picture/2.png" alt="shape">
        </div>
        <div class="shape3">
          <img src="static/picture/3.png" alt="shape">
        </div>
        <div class="shape4">
          <img src="static/picture/4.png" alt="shape">
        </div>
        <div class="shape5">
          <img src="static/picture/5.png" alt="shape">
        </div>
        <div class="shape6">
          <img src="static/picture/6.png" alt="shape">
        </div>
        <div class="shape7">
          <img src="static/picture/3.png" alt="shape">
        </div>
      </div>
    </div>

    <!-- <div class="brand-area brand-bg ptb-100">
      <div class="container">
        <div class="brand-slider owl-carousel owl-theme">
          <div class="brand-item">
            <a >
              <img src="static/picture/11.png" alt="Brand Images">
            </a>
          </div>
          <div class="brand-item">
            <a >
              <img src="static/picture/21.png" alt="Brand Images">
            </a>
          </div>
          <div class="brand-item">
            <a >
              <img src="static/picture/31.png" alt="Brand Images">
            </a>
          </div>
          <div class="brand-item">
            <a >
              <img src="static/picture/41.png" alt="Brand Images">
            </a>
          </div>
          <div class="brand-item">
            <a >
              <img src="static/picture/51.png" alt="Brand Images">
            </a>
          </div>
        </div>
      </div>
    </div> -->

    <section class="portfolio-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span data-lang="menu.projectList1"></span>
          <p data-lang="menu.projectListDesc1"></p>
        </div>
        <div class="portfolio-slider pt-45 owl-carousel owl-theme">
          <div class="portfolio-item">
            <div class="portfolio-img">

              <img src="static/picture/1.jpg" alt="Portfolio Images">

              <div class="portfolio-content">
                <div style="position: relative;height: 40px;">
                  <div class="portfolio-tag">
                    <a><span data-lang="product.marketing1"></span></a>
                  </div>
                </div>

                <span data-lang="menu.marketDesc1"></span>
              </div>
            </div>
          </div>
          <div class="portfolio-item">
            <div class="portfolio-img">
              <a>
                <img src="static/picture/2.jpg" alt="Portfolio Images">
              </a>
              <div class="portfolio-content">
                <div style="position: relative;height: 40px;">
                  <div class="portfolio-tag">
                    <a><span data-lang="product.marketing2"></span></a>
                  </div>
                </div>
                <span data-lang="menu.marketDesc2"></span>
              </div>
            </div>
          </div>
          <div class="portfolio-item">
            <div class="portfolio-img">
              <img src="static/picture/3.jpg" alt="Portfolio Images">
              <div class="portfolio-content">
                <div style="position: relative;height: 40px;">
                  <div class="portfolio-tag">
                    <a><span data-lang="product.marketing3"></span></a>
                  </div>
                </div>
                <span data-lang="menu.marketDesc3"></span>
              </div>
            </div>
          </div>
          <div class="portfolio-item">
            <div class="portfolio-img">
              <a>
                <img src="static/picture/5.jpg" alt="Portfolio Images">
              </a>
              <div class="portfolio-content">
                <div style="position: relative;height: 40px;">
                  <div class="portfolio-tag">
                    <a><span data-lang="product.marketing6">Development</span></a>
                  </div>
                </div>
                <span data-lang="menu.marketDesc6"></span>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="about-area pt-100 pb-70">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-5 col-xxl-6 ps-0">
            <div class="about-img">
              <img src="static/picture/12.png" alt="About Images">
            </div>
          </div>
          <div class="col-lg-7 col-xxl-6">
            <div class="about-content about-width">
              <span data-lang="menu.projectList2"></span>
              <p data-lang="menu.projectListDesc2"></p>
              <div class="about-list">
                <p data-lang="product.voice"></p>
                <p data-lang="menu.voiceDesc"></p>
              </div>

              <div class="about-list">
                <p>SIP Trunk</p>
                <p data-lang="menu.trunkDesc"></p>
              </div>

              <!-- <div class="about-btn">
                <a  class="default-btn">Know More</a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="about-shape">
        <img src="static/picture/right-side.png" alt="Shape Images">
      </div>
    </div>

    <div class="counter-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-6 col-md-3">
            <div class="single-counter">
              <i class='flaticon-launch'></i>
              <h3><span class="counter">&lt;8s</span></h3>
              <p data-lang="menu.sendingTime">发送时长</p>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-md-3">
            <div class="single-counter">
              <i class='flaticon-customer'></i>
              <h3><span class="counter">150</span>+</h3>
              <p data-lang="menu.coveredCountries">覆盖国家</p>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-md-3">
            <div class="single-counter">
              <i class='flaticon-success'></i>
              <h3><span class="counter">500</span>+</h3>
              <p data-lang="menu.partnerOperators">合作运营商</p>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-md-3">
            <div class="single-counter">
              <i class='flaticon-team-building'></i>
              <h3><span class="counter">99</span>%</h3>
              <p data-lang="menu.responseRate">应答率</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="testimonial-area pt-100 pb-70">
      <div class="container-fluid">
        <div class="section-title text-center">
          <span data-lang="menu.projectList3"></span>

        </div>
        <div class="row align-items-center">
          <div class="col-lg-7 col-xxl-6">
            <div class="testimonial-slider">
              <div class="testimonial-icon">
                <i class='bx bxs-quote-alt-right'></i>
              </div>
              <div class="testimonial-item-slider owl-carousel owl-theme">
                <div class="testimonial-item">
                  <h3 data-lang="product.number"></h3>
                  <p data-lang="menu.phoneDesc"></p>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-5 col-xxl-6">
            <div class="testimonial-img">
              <img src="static/picture/15.png" alt="Testimonial Images">
            </div>
          </div>
        </div>
      </div>
      <div class="shape-left">
        <img src="static/picture/shape-left.png" alt="Images">
      </div>
      <div class="shape-right">
        <img src="static/picture/shape-right.png" alt="Images">
      </div>
    </div>

    <div class="faq-area pt-100 pb-70">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6 col-xxl-7">
            <div class="faq-img">
              <img src="static/picture/14.png" alt="fAQ Images">
            </div>
          </div>
          <div class="col-lg-6 col-xxl-5">
            <div class="faq-content">
              <span>WhatsApp</span>
              <p data-lang="menu.projectListDesc7">
              </p>
            </div>
            <div class="faq-accordion">
              <ul class="accordion">
                <li class="accordion-item">
                  <a class="accordion-title" href="javascript:void(0)">
                    <i class='bx bx-chevron-down'></i>
                    <span data-lang="special.special63"></span>
                  </a>
                  <div class="accordion-content">
                    <p data-lang="special.special64">

                    </p>
                  </div>
                </li>
                <li class="accordion-item">
                  <a class="accordion-title" href="javascript:void(0)">
                    <i class='bx bx-chevron-down'></i>
                    <span data-lang="special.special65"></span>
                  </a>
                  <div class="accordion-content">
                    <p data-lang="special.special66">

                    </p>
                  </div>
                </li>
                <li class="accordion-item">
                  <a class="accordion-title active" href="javascript:void(0)">
                    <i class='bx bx-chevron-down'></i>
                    <span data-lang="special.special67"></span>
                  </a>
                  <div class="accordion-content show">
                    <p data-lang="special.special68">

                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blog-area pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span data-lang="special.titleTop12">Our Latest Post</span>
          <p data-lang="special.projectListDesc14">

          </p>
        </div>
        <div class="row pt-45">
          <div class="col-lg-4 col-md-4">
            <div class="blog-card">
              <div class="blog-img">
                <a>
                  <img src="static/picture/12.jpg" alt="Blog Images">
                </a>
              </div>
              <div class="blog-content">
                <div class="blog-tag">
                  <a><span data-lang="special.marketing32"></span></a>
                </div>

                <p data-lang="special.marketDesc36"></p>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="blog-card">
              <div class="blog-img">
                <a>
                  <img src="static/picture/22.jpg" alt="Blog Images">
                </a>
              </div>
              <div class="blog-content">
                <div class="blog-tag">
                  <a><span data-lang="special.marketing33"></span></a>
                </div>
                <p data-lang="special.marketDesc37">How to Become Most Skilled Person in Social Marketing
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="blog-card">
              <div class="blog-img">
                <a>
                  <img src="static/picture/32.jpg" alt="Blog Images">
                </a>
              </div>
              <div class="blog-content">
                <div class="blog-tag">
                  <a><span data-lang="special.marketing34"></span></a>
                </div>
                <p data-lang="special.marketDesc38">How to Become Most Skilled Person in Social Marketing
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="service-area pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span data-lang="special.title"></span>
        </div>
        <div class="row pt-45">
          <div class="col-lg-4 col-sm-6">
            <div class="service-card">
              <a>
                <img src="static/picture/13.png" alt="Images">
              </a>
              <a>
                <h3 data-lang="special.special11">SEO Optimization</h3>
              </a>
              <p data-lang="special.special12">

              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="service-card">
              <a>
                <img src="static/picture/22.png" alt="Images">
              </a>
              <a>
                <h3 data-lang="special.special21">Social Marketing</h3>
              </a>
              <p data-lang="special.special22">

              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="service-card">
              <a>
                <img src="static/picture/32.png" alt="Images">
              </a>
              <a>
                <h3 data-lang="special.special31">Content Marketing</h3>
              </a>
              <p data-lang="special.special32">

              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="service-card">
              <a>
                <img src="static/picture/42.png" alt="Images">
              </a>
              <a>
                <h3 data-lang="special.special41">Analytical Analysis</h3>
              </a>
              <p data-lang="special.special42">
                It is a long established fact that area
                der will be distracted by the reada
                ble content of a page when looking.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="service-card">
              <a>
                <img src="static/picture/52.png" alt="Images">
              </a>
              <a>
                <h3 data-lang="special.special51">App Development</h3>
              </a>
              <p data-lang="special.special52">
                It is a long established fact that area
                der will be distracted by the reada
                ble content of a page when looking.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="service-card">
              <a>
                <img src="static/picture/61.png" alt="Images">
              </a>
              <a>
                <h3 data-lang="special.special61">Web Development</h3>
              </a>
              <p data-lang="special.special62">
                It is a long established fact that area
                der will be distracted by the reada
                ble content of a page when looking.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="submission-area ptb-100">
      <div class="container">
        <div class="submission-title">
          <h2 data-lang="foot.form.tips"></h2>
        </div>
        <div class="submission-form">
          <div class="row formBox" id="contactForm">

            <div class="col-lg-5 col-md-6">
              <div class="form-group">
                <input data-lang="foot.form.tips" type="text" class="form-control" v-model="form.content">
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group border-line">
                <input data-lang="foot.form.name" type="text" class="form-control" v-model="form.name">
              </div>
            </div>
            <div class="col-lg-5 col-md-6">
              <div class="form-group">
                <input data-lang="foot.form.phone" type="text" class="form-control" v-model="form.phone">
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="form-group border-line">
                <input data-lang="foot.form.email" type="text" class="form-control" v-model="form.email">
              </div>
            </div>

            <div class="col-lg-3 col-md-3 offset-md-5 offset-lg-0">
              <button class="default-btn" data-lang="foot.form.buttonText" @click="submitForm">Submit</button>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="price-area pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <h2 data-lang="product.tip"></h2>
          <p data-lang="product.priceTip">
          </p>
        </div>
        <div class="row">
          <div class="col-lg-12 pt-45">
            <div class="tabs-item-list">
              <ul id="tabs-item" class="text-center">
                <li class="active">
                  <a @click="goToPrice" data-lang="product.seePricing" class="prices-tab"></a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
  
  <style>
</style>
  
  <script>
export default {
  name: 'App',
  components: {
  },
  mounted() {
    this.custom()
    this.changeLang()
  },
  data() {
    return {
      form: {
        name: "",
        phone: "",
        email: "",
        content: "",
      },
      language: localStorage.getItem("language")
    }
  },
  methods: {
    
    submitForm() {
      if (!this.form.content) {
        let message =
          this.language == "CN" ? "请填写内容" : "Please fill in the content";
        this.$emit("msg", message, false);
      } else if (!this.form.name) {
        let message =
          this.language == "CN" ? "请填写您的姓名" : "Please fill in your name";
        this.$emit("msg", message, false);
      } else if (!this.form.phone && !this.form.email) {
        let message =
          this.language == "CN"
            ? "请填写您的联系方式"
            : "Please fill in your contact information";
        this.$emit("msg", message, false);
      } else {

        $.ajax({
          type: "POST",
          url: "https://www.yoni-tech.com/api/leave-message/add",
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify(this.form)
        });
        this.form.name = "";
        this.form.phone = "";
        this.form.email = "";
        this.form.content = "";
        let message =
          this.language == "CN"
            ? "提交成功"
            : "Submit Success";
        this.$emit(
          "msg",
          message,
          true
        );
      }
    },
    gotoContact() {
      this.$router.push('/contactUs')
    },
    gotoAbout() {
      this.$router.push('/about')
    },
    goToPrice() {
      this.$router.push('/price')
    }
  }
}
  </script>
  
  