import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/index.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:catchAll(.*)',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/product',
  //   name: 'Product',
  //   component: () => import('../views/Product.vue')
  // },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs.vue')
  },
  {
    path: '/sms',
    name: 'SMS',
    component: () => import('../views/otp.vue')
  },
  {
    path: '/market',
    name: 'Market',
    component: () => import('../views/market.vue')
  },
  {
    path: '/voice',
    name: 'Voice',
    component: () => import('../views/voice.vue')
  },
  {
    path: '/trunk',
    name: 'Trunk',
    component: () => import('../views/trunk.vue')
  },
  {
    path: '/phoneCheck',
    name: 'Platform',
    component: () => import('../views/phoneCheck.vue')
  },
  {
    path: '/price',
    name: 'Price',
    component: () => import('../views/price.vue')
  },
  {
    path: '/email',
    name: 'Emial',
    component: () => import('../views/email.vue')
  },
  {
    path: '/termsService',
    name: 'TermsService',
    component: () => import('../views/TermsService.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue')
  },

  // {
  //   path: '/whatsApp',
  //   name: 'WhatsApp',
  //   component: () => import('../views/whatsApp.vue')
  // },
  {
    path: '/campaign',
    name: 'Campaign',
    component: () => import('../views/campaign.vue')
  },
  {
    path: '/bot',
    name: 'Bot',
    component: () => import('../views/bot.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/contact.vue')
  },
  {
    path: '/growthTool',
    name: 'GrowthTool',
    component: () => import('../views/growthTool.vue')
  },
  // {
  //   path: '/CTWA',
  //   name: 'CTWA',
  //   component: () => import('../views/CTWA.vue')
  // },
  {
    path: '/inbox',
    name: 'Inbox',
    component: () => import('../views/inbox.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  next()
});

export default router
